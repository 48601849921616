import { z } from 'zod';

import { TODO } from './global.types';

export interface Document {
  id: string;
  [key: string]: TODO;
}

export const documentSchema = z.object({
  id: z.string(),
  pinned: z.array(z.string()),
  readers: z.array(z.string()),
  editors: z.array(z.string()),
});

// export type Document = z.infer<typeof documentSchema>;
