/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  collection,
  doc,
  DocumentData,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  QueryDocumentSnapshot,
  serverTimestamp,
  startAfter,
  Timestamp,
  updateDoc,
} from 'firebase/firestore';
import _ from 'lodash';
import getNextConfig from 'next/config';
import create from 'zustand';

import { toast } from 'components/ui/use-toast';
import { db } from 'utils/api/firebase';

import { TODO } from './global.types';
import { Imagination } from './imaginations.types';

const env = getNextConfig().publicRuntimeConfig;

// April 1 2023
const placeholderTimestamp = new Timestamp(Date.UTC(2023, 3, 1) / 1000, 0);

export const imaginationsCollection = env.IMAGINATIONS_COLLECTION;
export const imaginationIterationsCollection =
  env.IMAGINATION_ITERATIONS_COLLECTION;
export interface ImaginationsStoreType {
  initialLoading: boolean;
  setInitialLoading: (initialLoading: boolean) => void;

  imaginations: Imagination[];
  syncImagination: (imagination: Imagination) => void;

  lastVisible: QueryDocumentSnapshot<DocumentData, DocumentData>;
  setLastVisible: (
    lastVisible: QueryDocumentSnapshot<DocumentData, DocumentData>
  ) => void;

  hasMore: boolean;
  setHasMore: (hasMore: boolean) => void;

  getImaginations: (wsId: string, uid: string, all?: boolean) => void;

  getImagination: (wsId: string, imaginationId: string) => TODO;

  limitCount: number;

  updateImagination: (
    docRef: TODO,
    data: TODO,
    uid?: string,
    pId?: string,
    cId?: string
  ) => void;
}

const useImaginationsStore = create<ImaginationsStoreType>((set, get) => ({
  initialLoading: true,
  setInitialLoading: initialLoading => {
    set(() => ({ initialLoading }));
  },

  imaginations: [],
  syncImagination: imagination => {
    set(({ imaginations }) => {
      const imaginationWithDefaults = {
        createdAt: placeholderTimestamp,
        updatedAt: placeholderTimestamp,
        width: 1,
        height: 1,
        collections: [],
        projects: [],
        liked: [],
        ...imagination,
      };
      const imaginationIndex = imaginations.findIndex(
        i => i.id === imaginationWithDefaults.id
      );
      if (imaginationIndex === -1)
        return { imaginations: [...imaginations, imaginationWithDefaults] };
      const updatedimaginations = imaginations
        .map((i, index) =>
          index === imaginationIndex ? { ...i, ...imaginationWithDefaults } : i
        )
        .filter(i => i.isArchive !== true);
      return {
        imaginations: _.orderBy(
          updatedimaginations,
          [
            imagination => {
              return imagination.updatedAt.toDate();
            },
          ],
          ['desc']
        ),
      };
    });
  },

  limitCount: 34,

  hasMore: true,
  setHasMore: hasMore => {
    set(() => ({ hasMore }));
  },

  lastVisible: null,
  setLastVisible: lastVisible => {
    set(() => ({ lastVisible }));
  },

  getImaginations: async (wsId, uid, all) => {
    const {
      limitCount,
      lastVisible,
      syncImagination,
      hasMore,
      setHasMore,
      setLastVisible,
      getImaginations,
      setInitialLoading,
    } = get();

    if (!hasMore) return;

    let q = query(collection(db, 'users', wsId, imaginationsCollection));

    q = query(q, orderBy('updatedAt', 'desc'), limit(limitCount));

    if (lastVisible) {
      q = query(q, startAfter(lastVisible));
    }

    const documentSnapshots = await getDocs(q);
    setInitialLoading(false);
    documentSnapshots.forEach(doc => {
      syncImagination({ id: doc.id, ref: doc.ref, ...doc.data() });
    });

    if (documentSnapshots.empty) {
      setHasMore(false);
    }
    if (documentSnapshots.size) {
      setLastVisible(documentSnapshots.docs[documentSnapshots.docs.length - 1]);
      if (all) getImaginations(wsId, uid, all);
    }
  },

  getImagination: async (wsId, imaginationId) => {
    const { imaginations, syncImagination } = get();
    const syncedImagination = imaginations.find(i => i.id === imaginationId);
    if (syncedImagination) return syncedImagination;
    const docRef = doc(
      db,
      'users',
      wsId,
      imaginationsCollection,
      imaginationId
    );
    const docSnap = await getDoc(docRef);
    const imagination = docSnap.exists()
      ? { id: docSnap.id, ref: docSnap.ref, ...docSnap.data() }
      : null;
    if (imagination) syncImagination(imagination);
    return imagination;
  },
  updateImagination: async (docRef, data, uid) => {
    const { skipUpdatedAt, ...restProps } = data;
    try {
      await updateDoc(docRef, {
        ...restProps,
        ...(uid && { updatedBy: uid }),
        ...(!skipUpdatedAt && { updatedAt: serverTimestamp() }),
      });
    } catch (error) {
      console.log('useBrandsStore -- updateBrand -- error:', error);
      toast({
        title: 'Something went wrong. Please try again.',
      });
    }
  },
}));

export default useImaginationsStore;
