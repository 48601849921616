import { zodResolver } from '@hookform/resolvers/zod';
import { getDoc } from 'firebase/firestore';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as z from 'zod';

import { Button } from 'components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from 'components/ui/dialog';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'components/ui/form';
import { Input } from 'components/ui/input';
import { Textarea } from 'components/ui/textarea';
import useCollectionsStore from 'store/collections';
import { ROUTES } from 'utils/routes';
import { colors } from 'utils/styles/theme';

const formSchema = z.object({
  name: z.string().nonempty({ message: 'Name is required' }),
  description: z.string(),
  appearance: z.string(),
});

const formValueDefaults = {
  name: '',
  description: '',
  appearance: colors.black,
};

const getFormValues = (data, defaults) => {
  return Object.keys(defaults).reduce((values, key) => {
    values[key] = data && data[key] !== undefined ? data[key] : defaults[key];
    return values;
  }, {});
};

const CollectionFormView = ({ open, onOpenChange, collectionRef }) => {
  const router = useRouter();
  const wsId = router.query.wsId as string;
  const { data } = useSession();
  const uid = data?.user?.id;
  const { createCollection, updateCollection } = useCollectionsStore();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: formValueDefaults,
  });

  useEffect(() => {
    if (collectionRef) {
      getDoc(collectionRef).then(doc => {
        const data = doc.data();
        form.reset(getFormValues(data, formValueDefaults));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    if (process.env.IS_DEBUG)
      console.log('CollectionFormView -- onSubmit -- values:', values);
    if (collectionRef) {
      updateCollection(collectionRef, values);
    } else {
      createCollection(wsId, uid, values);
    }
    onOpenChange(false);

    if (!collectionRef) {
      router.push(ROUTES.COLLECTIONS.replace('[wsId]', wsId));
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{`${
            collectionRef ? 'Edit' : 'New'
          } collection`}</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Name</FormLabel>
                  <FormControl>
                    <Input placeholder={'Add a title...'} {...field} />
                  </FormControl>
                  <FormDescription></FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Description</FormLabel>
                  <FormControl>
                    <Textarea
                      placeholder={'Describe your collection...'}
                      rows={4}
                      {...field}
                    />
                  </FormControl>
                  <FormDescription></FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="appearance"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Appearance</FormLabel>
                  <FormControl>
                    <Input type={'color'} {...field} className={'max-w-36'} />
                  </FormControl>
                  <FormDescription></FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />

            <Button type="submit" className={'float-end'}>
              {collectionRef ? 'Update' : 'Create collection'}
            </Button>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
export default CollectionFormView;
