import { loadStripe, Stripe as StripeClient } from '@stripe/stripe-js';
import getNextConfig from 'next/config';
import Stripe from 'stripe';

const env = getNextConfig().publicRuntimeConfig;
const envServer = getNextConfig().serverRuntimeConfig;

export const stripe = new Stripe(envServer.STRIPE_SECRET_KEY, {
  apiVersion: '2023-08-16',
});

let stripePromise: Promise<StripeClient | null>;
export const stripeClient = async () => {
  if (!stripePromise) {
    stripePromise = loadStripe(env.STRIPE_PUBLISHABLE_KEY);
  }
  return stripePromise;
};
