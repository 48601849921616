import { ref, uploadBytes } from 'firebase/storage';
import _ from 'lodash';
import sanitize from 'sanitize-filename';

import {
  BUCKET_URL,
  BUCKET_URL_PREFIX,
  projectId,
  storage,
} from './api/firebase';
import { nanoid } from 'nanoid';

export function generateName(file: File, fileId: string) {
  const fileExt = file.name.split('.').pop();
  return `${fileId}.${fileExt}`;
}

export const toDataUrl = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

export const dataUrlToBlob = async (dataUrl: string, fileType: string) => {
  const binaryString = Buffer.from(dataUrl.split(',')[1], 'base64').toString(
    'binary'
  );
  const binaryData = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
    binaryData[i] = binaryString.charCodeAt(i);
  }
  return new Blob([binaryData], { type: fileType });
};

export const getFormattedDate = (
  date: Date | string | number,
  displayLocale: string,
  withTime = true
) =>
  new Intl.DateTimeFormat(displayLocale, {
    dateStyle: 'medium',
    ...(withTime && { timeStyle: 'medium' }),
  }).format(new Date(date));

export enum ThumbnailSize {
  '_144' = '_144x144',
  '_377' = '_377x377',
  '_610' = '_610x610',
  '_1597' = '_1597x1597',
}

export const thumbUrl = (url: string, size: ThumbnailSize): string => {
  // Adjusted regex pattern to capture the path before the filename and the filename with extension
  // eslint-disable-next-line no-useless-escape
  const pattern = /(.+images%2F)([^\/]+)(\.\w+)(\?.*)?$/;
  const match = url.match(pattern);
  if (match) {
    const pathBeforeImages = match[1];
    const filename = match[2];
    const extension = match[3];
    const queryParams = match[4] || ''; // Default to empty string if no query parameters
    const newFilename = `${pathBeforeImages}thumbs%2F${filename}${size}${extension}${queryParams}`;
    return newFilename;
  }
  return url;
};

export const createUniqueFilename = (props: {
  index?: string;
  brandPrefix?: string;
  projectPrefix?: string;
  title?: string;
  text: string;
  extension: string;
  suffix?: string;
}) => {
  const {
    index = '',
    brandPrefix = '',
    projectPrefix = '',
    title = '',
    text,
    suffix = 'euryka',
    extension,
  } = props;
  const titleSanitize = sanitize(title).replace(/[^a-zA-Z0-9]/g, '_');
  const textSanitize = sanitize(text).replace(/[^a-zA-Z0-9]/g, '_');
  const fileName = `${index ? `${index}-` : ''}${
    brandPrefix
      ? `${_.truncate(brandPrefix, { length: 10, omission: '...' })}-`
      : ''
  }${
    projectPrefix
      ? `${_.truncate(projectPrefix, { length: 10, omission: '...' })}-`
      : ''
  }${
    titleSanitize
      ? `${_.truncate(titleSanitize, { length: 10, omission: '...' })}-`
      : ''
  }${
    textSanitize
      ? `${_.truncate(textSanitize, { length: 10, omission: '...' })}-`
      : ''
  }${suffix ? `${suffix}` : ''}.${extension}`;
  return sanitize(fileName);
};

export const uploadFile = async (props: { wsId: string; file: File }) => {
  try {
    if (process.env.IS_DEBUG)
      console.log('file -- uploadFile -- props:', props);

    const { wsId, file } = props;
    if (!props.file) throw new Error('No file provided');

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const originalFileName = file.name;
    const fileName = generateName(file, nanoid());
    const isImage = file.type.includes('image');
    const storageRef = ref(
      storage,
      `${BUCKET_URL}/users/${wsId}/${isImage ? 'images' : 'files'}/${fileName}`
    );
    await uploadBytes(storageRef, file, {
      contentType: file.type,
      cacheControl: 'public, max-age=31536000, immutable',
    });

    return `${BUCKET_URL_PREFIX}users%2F${wsId}%2F${
      isImage ? 'images' : 'files'
    }%2F${fileName}?alt=media`;
  } catch (error) {
    console.log('file -- uploadFile -- error:', error);
  }
};

export const getFilePath = (url: string): string => {
  try {
    const parsedUrl = new URL(url);
    const pathWithQuery = parsedUrl.pathname.split('/o/')[1];
    const decodedPath = decodeURIComponent(pathWithQuery.split('?')[0]);
    return decodedPath;
  } catch (error) {
    console.error('getFilePath -- error:', error);
    return url;
  }
};

export const getGcsFileUrl = (filePath: string) => {
  return `https://storage.googleapis.com/${projectId}.appspot.com/${filePath}`;
};
