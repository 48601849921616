export {};

export const aspectSizes = {
  square: '512x512',
  landscape: '768x512',
  'landscape-wide': '1280x720',
  portrait: '512x768',
  'portrait-tall': '720x1280',
};

export const aspectSizesDallE = {
  square: '1024x1024',
  landscape: '1792x1024',
  'landscape-wide': '1792x1024',
  portrait: '1024x1792',
  'portrait-tall': '1024x1792',
};

export const aspectRatios = {
  square: '1:1',
  landscape: '3:2',
  'landscape-wide': '16:9',
  portrait: '2:3',
  'portrait-tall': '9:16',
};
