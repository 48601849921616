/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  doc,
  DocumentData,
  getDoc,
  getDocs,
  limit,
  or,
  orderBy,
  query,
  QueryDocumentSnapshot,
  serverTimestamp,
  startAfter,
  updateDoc,
  where,
} from 'firebase/firestore';
import _ from 'lodash';
import create from 'zustand';

import { toast } from 'components/ui/use-toast';
import { db } from 'utils/api/firebase';

import { TODO } from './global.types';
import { Voiceover } from './voiceovers.types';

export interface VoiceoversStoreType {
  initialLoading: boolean;
  setInitialLoading: (initialLoading: boolean) => void;

  voiceovers: Voiceover[];
  syncVoiceover: (collection: Voiceover) => void;

  lastVisible: QueryDocumentSnapshot<DocumentData, DocumentData>;
  setLastVisible: (
    lastVisible: QueryDocumentSnapshot<DocumentData, DocumentData>
  ) => void;

  hasMore: boolean;
  setHasMore: (hasMore: boolean) => void;

  limitCount: number;

  getVoiceovers: (wsId: string, uid: string, all?: boolean) => void;

  getVoiceover: (wsId: string, voiceoverId: string) => TODO;

  createVoiceover: (wsId: string, uid: string, data: TODO) => void;

  updateVoiceover: (docRef: TODO, data: TODO, uid?: string) => void;
}

const useVoiceoversStore = create<VoiceoversStoreType>((set, get) => ({
  initialLoading: true,
  setInitialLoading: initialLoading => {
    set(() => ({ initialLoading }));
  },
  voiceovers: [],
  syncVoiceover: voiceover => {
    set(({ voiceovers }) => {
      const voiceoverIndex = voiceovers.findIndex(v => v.id === voiceover.id);
      if (voiceoverIndex === -1)
        return { voiceovers: [...voiceovers, voiceover] };
      const updatedVoiceovers = voiceovers.map((t, index) =>
        index === voiceoverIndex ? { ...t, ...voiceover } : t
      );

      return {
        voiceovers: _.sortBy(updatedVoiceovers, ['name']),
      };
    });
  },

  lastVisible: null,
  setLastVisible: lastVisible => {
    set(() => ({ lastVisible }));
  },

  hasMore: true,
  setHasMore: hasMore => {
    set(() => ({ hasMore }));
  },

  limitCount: 25,

  getVoiceovers: async (wsId, uid, all = false) => {
    if (!wsId || !uid) return;
    const {
      limitCount,
      lastVisible,
      syncVoiceover,
      hasMore,
      setHasMore,
      setLastVisible,
      getVoiceovers,
      setInitialLoading,
    } = get();

    if (!hasMore) return;

    // init q
    let q = query(
      collection(db, 'users', wsId, 'voiceovers')
      // or(where('isArchive', 'in', [false, null]))
    );
    // add order desc and limit
    q = query(q, orderBy('createdAt', 'desc'), limit(limitCount));
    // has no more starred
    if (lastVisible) {
      q = query(q, startAfter(lastVisible));
    }
    const documentSnapshots = await getDocs(q);
    setInitialLoading(false);
    documentSnapshots.forEach(doc => {
      syncVoiceover({ id: doc.id, ref: doc.ref, ...doc.data() });
    });
    if (documentSnapshots.empty) {
      setHasMore(false);
    }
    if (documentSnapshots.size) {
      setLastVisible(documentSnapshots.docs[documentSnapshots.size - 1]);
      if (all) getVoiceovers(wsId, uid, all);
    }
  },

  getVoiceover: async (wsId, voiceoverId) => {
    if (!wsId || !voiceoverId) return;
    const { voiceovers, syncVoiceover } = get();
    const syncedVoiceover = voiceovers.find(p => p.id === voiceoverId);
    if (syncedVoiceover) return syncedVoiceover;
    const docRef = doc(db, 'users', wsId, 'voiceovers', voiceoverId);
    const docSnap = await getDoc(docRef);
    const voiceover = docSnap.exists()
      ? { id: docSnap.id, ref: docSnap.ref, ...docSnap.data() }
      : null;
    if (voiceover) syncVoiceover(voiceover);
    return voiceover;
  },

  createVoiceover: async (wsId, uid, data) => {
    try {
      if (!wsId) throw new Error('Workspace ID is required.');
      await addDoc(collection(db, 'users', wsId, 'voiceovers'), {
        ...data,
        createdBy: uid,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      });
    } catch (error) {
      console.log('useVoiceoversStore -- createVoiceover -- error:', error);
      toast({
        title: 'Something went wrong. Please try again.',
      });
    }
  },

  updateVoiceover: async (docRef, data, uid) => {
    const { starred, ...restProps } = data;
    try {
      await updateDoc(docRef, {
        ...restProps,
        ...(data['starred'] !== undefined && uid
          ? {
              starred:
                data['starred'] === 'remove'
                  ? arrayRemove(uid)
                  : arrayUnion(uid),
            }
          : {}),
        ...(uid ? { updatedBy: uid } : {}),
        updatedAt: serverTimestamp(),
      });
    } catch (error) {
      console.log('useVoiceoversStore -- updateVoiceover -- error:', error);
      toast({
        title: 'Something went wrong. Please try again.',
      });
    }
  },
}));

export default useVoiceoversStore;
