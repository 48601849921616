import React, { useEffect, useMemo, useState } from 'react';

import { Button } from 'components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from 'components/ui/dialog';
import useAccount from 'hooks/useAccount';
import useCommon from 'hooks/useCommon';
import useUser from 'hooks/useUser';
import useUsersStore from 'store/users';
import { ROUTES } from 'utils/routes';

interface InviteDialogProps {
  wsId: string;
  uid: string;
  uemail: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const IS_DEBUG = process.env.IS_DEBUG && true;

const InviteDialog: React.FunctionComponent<InviteDialogProps> = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  wsId,
  uid,
  uemail,
}) => {
  const { router } = useCommon();
  const [isInviteOpen, setInviteOpen] = useState(false);
  const { memberWorkspaces, updateUser } = useUsersStore();
  const { onUpdateInvite } = useAccount();

  const inviteId = useMemo(() => {
    return memberWorkspaces.find(mw => mw.status === 'pending')?.id || null;
  }, [memberWorkspaces]);

  const { orgName, name } = useUser({ uid: inviteId });

  useEffect(() => {
    setInviteOpen(!!inviteId);
  }, [inviteId]);

  return (
    <Dialog
      open={isInviteOpen}
      onOpenChange={open => {
        setInviteOpen(open);
      }}
    >
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Workspace Invite</DialogTitle>
          <DialogDescription
            dangerouslySetInnerHTML={{
              __html: `You have been invited to join <b>${
                orgName || name || 'a workspace'
              }</b>.`,
            }}
          ></DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <Button
            variant={'outline'}
            onClick={() => {
              onUpdateInvite(uemail, inviteId, 'declined');
              setInviteOpen(false);
            }}
          >
            Decline
          </Button>
          <Button
            onClick={async () => {
              await onUpdateInvite(uemail, inviteId, 'accepted');
              await updateUser(uid, {
                'user.defaultWorkspace': inviteId,
              });
              setInviteOpen(false);
              router.replace(ROUTES.WORKSPACE).then(() => {
                router.reload();
              });
            }}
          >
            Accept
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
export default InviteDialog;
