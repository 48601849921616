import { MoonIcon, SunIcon } from '@radix-ui/react-icons';
import {
  Bell,
  Bolt,
  Check,
  Info,
  LogOut,
  Plus,
  SwatchBook,
} from 'lucide-react';
import { useTheme } from 'next-themes';
import React, { useCallback, useMemo } from 'react';

import { Badge } from 'components/ui/badge';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu';
import { WsAvatar } from 'components/WsAvatar';
import useCommon from 'hooks/useCommon';
import useUser from 'hooks/useUser';
import { useGlobalStore } from 'store';
import useUsersStore from 'store/users';
import { ROUTES } from 'utils/routes';

export interface SidebarAccountProps {
  wsId: string;
  uid: string;
}

const defaultProps: Partial<SidebarAccountProps> = {};

const SidebarAccount: React.FC<SidebarAccountProps> = ({ wsId, uid }) => {
  const { setCommandOpen, setNotificationsOpen } = useGlobalStore();
  const { memberWorkspaces } = useUsersStore();

  const { user: workspace, orgName, orgPhotoURL } = useUser({ uid: wsId });
  const { photoURL, name } = useUser({ uid });

  const { notifications, updateUser } = useUsersStore();
  const { router, routeTo, onLogout } = useCommon();
  const { theme, setTheme } = useTheme();

  const onAccountClick = useCallback(() => {
    routeTo(ROUTES.ACCOUNT_ORG, uid);
  }, [routeTo, uid]);

  const unreadNotifications = useMemo(() => {
    return notifications?.filter(notification => !notification.isRead).length;
  }, [notifications]);

  return (
    <div className={'sticky bottom-0 p-3 flex flex-col items-center gap-4'}>
      <div
        className={
          'bg-muted rounded-full aspect-square w-8 flex items-center justify-center cursor-pointer bg-cover bg-center bg-no-repeat'
        }
        style={{
          backgroundImage: 'url(/images/ek-technicolor.png)',
        }}
        onClick={() => {
          setCommandOpen(true);
        }}
      >
        <Plus className={'h-4 w-4 text-white'} />
      </div>
      <DropdownMenu>
        <DropdownMenuTrigger>
          <div className={'relative aspect-square'}>
            <WsAvatar
              {...{
                photoURL,
                name,
                size: '48',
              }}
            />
            <div className={'absolute -bottom-0.5 -left-0.5'}>
              <WsAvatar
                {...{
                  photoURL: orgPhotoURL,
                  name: orgName,
                  size: '20',
                  sx: `border-2 border-solid ${
                    workspace?.uidColor ? `border-[${workspace.uidColor}]` : ''
                  }`,
                  fallback: <SwatchBook className={'h-4 w-4'} />,
                }}
              />
            </div>
            {unreadNotifications > 0 && (
              <>
                <div
                  className={
                    'absolute top-0 bg-eurykaRed -right-0.5 w-4 h-4 rounded-full'
                  }
                />
                <div
                  className={
                    'absolute top-0 bg-eurykaRed -right-0.5 w-4 h-4 rounded-full animate-ping animate-thrice'
                  }
                />
              </>
            )}
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent side={'right'} align={'end'}>
          {!!memberWorkspaces?.length && (
            <DropdownMenuLabel>Workspaces</DropdownMenuLabel>
          )}
          {[...memberWorkspaces.map(ws => ws.id), uid]?.map(id => (
            <DropdownMenuItem
              key={id}
              onClick={async () => {
                if (wsId === id) return;
                await updateUser(uid, {
                  'user.defaultWorkspace': id,
                });
                await router.replace(ROUTES.WORKSPACE);
                router.reload();
              }}
            >
              <Ws {...{ uid: id }} />
              {id === wsId && <Check className={'ml-2 h-4 w-4'} />}
            </DropdownMenuItem>
          ))}
          <DropdownMenuSeparator />
          <DropdownMenuItem
            onClick={() => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              window?.Intercom?.('showSpace', 'help');
            }}
          >
            <Info className={'h-4 w-4 mr-2'} />
            <span className={'text-sm font-normal text-muted-foreground'}>
              Help
            </span>
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => setNotificationsOpen(true)}>
            <Bell className={'h-4 w-4 mr-2'} />
            <span className={'text-sm font-normal text-muted-foreground'}>
              Notifications
            </span>
            {unreadNotifications > 0 && (
              <Badge variant={'destructive'} className={'ml-2 rounded-full'}>
                {unreadNotifications}
              </Badge>
            )}
          </DropdownMenuItem>
          <DropdownMenuSub>
            <DropdownMenuSubTrigger>
              {theme === 'light' && <SunIcon className="h-4 w-4 mr-2" />}
              {theme === 'dark' && <MoonIcon className="h-4 w-4 mr-2" />}
              <span className={'text-sm font-normal text-muted-foreground'}>
                Toggle theme
              </span>
            </DropdownMenuSubTrigger>
            <DropdownMenuPortal>
              <DropdownMenuSubContent>
                <DropdownMenuItem onClick={() => setTheme('light')}>
                  Light
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => setTheme('dark')}>
                  Dark
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => setTheme('system')}>
                  System
                </DropdownMenuItem>
              </DropdownMenuSubContent>
            </DropdownMenuPortal>
          </DropdownMenuSub>
          <DropdownMenuItem onClick={() => onAccountClick()}>
            <Bolt className={'h-4 w-4 mr-2'} />
            <span className={'text-sm font-normal text-muted-foreground'}>
              Account
            </span>
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuItem onClick={() => onLogout()}>
            <LogOut className={'h-4 w-4 mr-2'} />
            <span className={'text-sm font-normal text-muted-foreground'}>
              Logout
            </span>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};

SidebarAccount.defaultProps = defaultProps;

export default SidebarAccount;

const Ws = (props: { uid: string }) => {
  const { uid } = props;
  const { name, orgName, orgPhotoURL } = useUser({ uid });

  return (
    <>
      {orgPhotoURL ? (
        <img
          src={orgPhotoURL}
          alt={orgName}
          className={'rounded-full object-cover w-4 h-4 overflow-hidden mr-2'}
        />
      ) : (
        <SwatchBook className={'h-4 w-4 mr-2'} />
      )}
      <span
        className={
          'text-sm text-muted-foreground line-clamp-1 break-all whitespace-normal'
        }
      >{`${orgName || name || 'Workspace'}`}</span>
    </>
  );
};
