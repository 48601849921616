import isValidProp from '@emotion/is-prop-valid';
import * as Sentry from '@sentry/react';
import { AnimatePresence, MotionConfig } from 'framer-motion';
import { CustomEase } from 'gsap/dist/CustomEase';
import { gsap } from 'gsap/dist/gsap';
import { ScrollToPlugin } from 'gsap/dist/ScrollToPlugin';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { SplitText } from 'gsap/dist/SplitText';
import type { AppProps } from 'next/app';
import Error from 'next/error';
import { SessionProvider } from 'next-auth/react';
if (process.env.ENV !== 'local') {
  Sentry.init({
    enabled: process.env.NODE_ENV !== 'development',
    dsn: process.env.SENTRY_DSN,
    environment: process.env.ENV,
    release: process.env.VERSION,
  });
}
import NextNProgress from 'nextjs-progressbar';
import React, { useEffect, useMemo } from 'react';
import { useIsClient, useIsMounted } from 'usehooks-ts';

import Main from 'components/layouts/Main';
import { TooltipProvider } from 'components/ui/tooltip';
import { setupStoreDevTools, useCopyStore } from 'store';
import { ROUTES } from 'utils/routes';
import customFonts from 'utils/styles/fonts';
import GlobalStyles from 'utils/styles/globalStyles';
import { colors } from 'utils/styles/theme';
import { ThemeProvider } from 'utils/styles/themeProvider';

import 'utils/styles/global.css';
import 'intersection-observer';
import 'inter-ui/inter.css';
import ErrorBoundary from 'growl/components/ErrorBoundary/ErrorBoundary';
import Head from 'growl/components/Head/Head';
import Version from 'growl/components/versioning/Version/Version';
import VersionScreen from 'growl/components/versioning/VersionScreen/VersionScreen';
import NonFunctionals from 'growl/containers/NonFunctionals/NonFunctionals';
import GTM from 'growl/utils/gtm';

const IS_DEBUG = process.env.IS_DEBUG && true;

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, SplitText, CustomEase);
gsap.defaults({ overwrite: 'auto', duration: 0.3612, ease: 'power3' });
gsap.config({ nullTargetWarn: false });

if (process.env.ENV !== 'local') {
  Sentry.init({
    enabled: true, // process.env.NODE_ENV !== 'development',
    dsn: process.env.SENTRY_DSN,
    environment: process.env.ENV,
    release: process.env.VERSION,
    debug: true,
  });
}

const App = ({ Component, pageProps, router }: AppProps) => {
  const isMounted = useIsMounted();
  const isClient = useIsClient();
  const { copy, setCopy } = useCopyStore();

  const ErrorBoundaryComponent = useMemo(
    () => (process.env.ENV === 'local' ? ErrorBoundary : Sentry.ErrorBoundary),
    []
  );

  useEffect(() => {
    if (IS_DEBUG) setupStoreDevTools();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (pageProps.initialCopy) setCopy(pageProps.initialCopy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageProps.initialCopy]);

  useEffect(() => {
    GTM.trackPage();
  }, [router.route]);

  return (
    <React.StrictMode>
      <ThemeProvider
        attribute="class"
        defaultTheme="light"
        enableSystem
        disableTransitionOnChange
      >
        <GlobalStyles />
        <SessionProvider
          session={pageProps.session}
          refetchInterval={60}
          refetchOnWindowFocus={true}
        >
          <TooltipProvider>
            <MotionConfig isValidProp={isValidProp}>
              <style dangerouslySetInnerHTML={{ __html: customFonts }} />
              <ErrorBoundaryComponent
                {...(process.env.ENV !== 'local'
                  ? { fallback: <Error statusCode={isMounted ? 400 : 500} /> }
                  : {})}
              >
                <Head {...(pageProps.initialCopy || copy).head} />

                <NonFunctionals
                  // If the non-functionals are the page component, pass the initial copy
                  // fetched from the server instead of the stored copy
                  initialCopy={
                    router.route === ROUTES.NOT_FOUND
                      ? pageProps.initialCopy
                      : copy
                  }
                  router={router}
                >
                  <AnimatePresence mode="wait" initial={false}>
                    <Main>
                      <NextNProgress
                        color={colors.eurykaRed}
                        startPosition={0.21}
                        stopDelayMs={34}
                        height={1}
                        showOnShallow={true}
                        options={{ showSpinner: false }}
                      />
                      <Component
                        key={router.route}
                        router={router}
                        {...pageProps}
                      />
                    </Main>
                  </AnimatePresence>
                </NonFunctionals>
              </ErrorBoundaryComponent>

              {IS_DEBUG && <Version />}
              {isClient
                ? IS_DEBUG &&
                  window.location.hash === '#version' && <VersionScreen />
                : null}
            </MotionConfig>
          </TooltipProvider>
        </SessionProvider>
      </ThemeProvider>
    </React.StrictMode>
  );
};

export default App;
