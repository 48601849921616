/* eslint-disable @typescript-eslint/no-unused-vars */
import _ from 'lodash';
import { PanelLeftOpen, PanelRightOpen } from 'lucide-react';
import React, { useCallback, useMemo } from 'react';
import { useLocalStorage } from 'usehooks-ts';

import { Icon } from 'components/lucide';
import { Separator } from 'components/ui/separator';
import useCommon from 'hooks/useCommon';
import { TODO } from 'store/global.types';
import { cn } from 'utils';
import { superAdmins, superAdminsExtended } from 'utils/config';
import {
  isAccountPage,
  isDashboardPage,
  isDocPage,
  isThreadPage,
  replaceDynamicSegments,
  ROUTES,
} from 'utils/routes';

export interface SidebarNavProps {
  [key: string]: TODO;
}

const defaultProps: Partial<SidebarNavProps> = {};

export const DashboardItems = {
  steps: [
    {
      id: 'dashboard',
      icon: 'LayoutDashboard',
      label: 'Dashboard',
    },
    {
      id: 'imaginations',
      icon: 'Sparkles',
      label: 'Imaginations',
    },
    {
      id: 'threads',
      icon: 'MessageSquareQuote',
      label: 'Threads',
    },
    {
      id: 'docs',
      icon: 'Files',
      label: 'Docs',
      isPreviewWs: true,
    },
    {
      id: 'characters',
      icon: 'VenetianMask',
      label: 'Characters',
      parentId: 'scripts',
    },
    {
      id: 'voiceovers',
      icon: 'AudioWaveform',
      label: 'Voiceovers',
      parentId: 'scripts',
    },
    {
      id: 'brands',
      icon: 'Pyramid',
      label: 'Brands',
    },
    {
      id: 'projects',
      icon: 'LibraryBig',
      label: 'Projects',
    },
    {
      id: 'collections',
      icon: 'Bookmark',
      label: 'Collections',
    },
    {
      id: 'divider1',
      label: 'Divider',
      isSuperAdmin: true,
    },
    {
      id: 'playground',
      icon: 'PencilRuler',
      label: 'Playground',
      isSuperAdmin: true,
    },
  ],
};

export const AccountItems = {
  steps: [
    {
      id: 'account_org',
      icon: 'Building',
      label: 'Organization',
    },
    {
      id: 'account_profile',
      icon: 'CircleUser',
      label: 'Profile',
    },
    {
      id: 'account_team',
      icon: 'Users',
      label: 'Team',
    },
    // {
    //   id: 'account_integrations',
    //   icon: 'Blocks',
    //   label: 'Integrations',
    // },
    {
      id: 'account_billing',
      icon: 'Receipt',
      label: 'Subscription',
    },
    {
      id: 'account_usage',
      icon: 'BarChart2',
      label: 'Usage',
    },
    {
      id: 'account_users',
      icon: 'BookUser',
      label: 'Users',
      isSuperAdmin: true,
    },
    {
      id: 'account_jobs',
      icon: 'Logs',
      label: 'Jobs',
      isSuperAdmin: true,
    },
  ],
};

const SidebarNav: React.FC<SidebarNavProps> = (props: { uid?: string }) => {
  const { uid } = props;
  const { router, wsId } = useCommon();

  const [isThreadPageCollapsed, setThreadPageCollapsed] = useLocalStorage(
    'isThreadPageCollapsed',
    false
  );

  const [isDocPageCollapsed, setDocPageCollapsed] = useLocalStorage(
    'isDocPageCollapsed',
    false
  );

  const onChange = useCallback(
    (id: string) => {
      if (id === 'threads' && isThreadPage(router)) {
        setThreadPageCollapsed(!isThreadPageCollapsed);
        return;
      }
      if (id === 'docs' && isDocPage(router)) {
        setDocPageCollapsed(!isDocPageCollapsed);
        return;
      }
      router.push(
        replaceDynamicSegments(
          ROUTES[_.snakeCase(id).toUpperCase()],
          router.query
        )
      );
    },
    [
      isDocPageCollapsed,
      isThreadPageCollapsed,
      router,
      setDocPageCollapsed,
      setThreadPageCollapsed,
    ]
  );

  const activeStepId = useMemo(() => {
    for (const [key, value] of Object.entries(ROUTES)) {
      if (value === router.route) return _.camelCase(key);
    }
    return '';
  }, [router.route]);

  const activeParentId = useMemo(() => {
    if (activeStepId === 'characters') return 'scripts';
    if (activeStepId === 'voiceOvers') return 'scripts';
  }, [activeStepId]);

  const items = useMemo(() => {
    let steps = [];
    if (isDashboardPage(router)) steps = DashboardItems.steps;
    if (isAccountPage(router)) steps = AccountItems.steps;

    return steps
      .filter(step => {
        if (step.isSuperAdmin) {
          return (
            superAdminsExtended.includes(wsId) || superAdmins.includes(uid)
          );
        }
        if (step.isPreviewWs) {
          return superAdminsExtended.includes(wsId);
        }
        return true;
      })
      .map(step => {
        const item: TODO = {
          ...step,
          isActive: step.id === activeStepId || step.id === activeParentId,
          ...(step.subItems && {
            subItems: step.subItems.map(subItem => {
              const subItemProps: TODO = {
                ...subItem,
                isActive: subItem.id === activeStepId,
              };
              return subItemProps;
            }),
          }),
        };
        return item;
      });
  }, [activeParentId, activeStepId, router, wsId, uid]);

  return (
    <nav className={cn('flex flex-col items-center')}>
      <ul className={'space-y-3'}>
        {items.map(item => {
          const { id, icon, label, isActive } = item;
          if (label === 'Divider') {
            return (
              <li key={id}>
                <Separator className={'my-4'} />
              </li>
            );
          }

          return (
            <li
              key={id}
              className={
                'flex lg:flex-col items-center gap-1 cursor-pointer group'
              }
              onClick={() => onChange(id)}
            >
              <div
                className={cn(
                  'w-[2.125rem] h-[2.125rem]  rounded-md flex items-center justify-center group-hover:bg-muted',
                  isActive ? 'bg-muted' : ''
                )}
              >
                <Icon
                  icon={icon}
                  className={cn(
                    'h-5.5 w-5.5',
                    isActive ? 'text-foreground' : 'text-muted-foreground'
                  )}
                  {...{
                    ...(isActive ? { strokeWidth: 2 } : { strokeWidth: 1.5 }),
                  }}
                />
              </div>
              <span
                className={cn(
                  'text-xs line-clamp-1',
                  isActive ? 'text-foreground' : 'text-muted-foreground'
                )}
              >
                {label}
              </span>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

SidebarNav.defaultProps = defaultProps;

export default SidebarNav;
