import { zodResolver } from '@hookform/resolvers/zod';
import { getDoc } from 'firebase/firestore';
import { CheckIcon, X } from 'lucide-react';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as z from 'zod';

import { Badge } from 'components/ui/badge';
import { Button } from 'components/ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from 'components/ui/command';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from 'components/ui/dialog';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'components/ui/form';
import { Input } from 'components/ui/input';
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover';
import useCommon from 'hooks/useCommon';
import useProjectsStore from 'store/projects';
import useVoiceoversStore from 'store/voiceovers';
import { cn } from 'utils';
import { ROUTES } from 'utils/routes';

const formSchema = z.object({
  title: z.string().nonempty({ message: 'Title is required' }),
  projects: z.array(z.string()).optional(),
});

const formValueDefaults = {
  title: '',
  projects: [],
};

const getFormValues = (script, defaults) => {
  return Object.keys(defaults).reduce((values, key) => {
    values[key] =
      script && script[key] !== undefined ? script[key] : defaults[key];
    return values;
  }, {});
};

const VoiceoverFormView = ({ open, onOpenChange, voiceoverRef, wsId, uid }) => {
  const { router } = useCommon();
  const { projects: projectsData } = useProjectsStore();

  const { createVoiceover, updateVoiceover } = useVoiceoversStore();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: formValueDefaults,
  });

  useEffect(() => {
    if (voiceoverRef) {
      getDoc(voiceoverRef).then(doc => {
        const data = doc.data();
        form.reset(getFormValues(data, formValueDefaults));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    if (process.env.IS_DEBUG)
      console.log('VoiceoverFormView -- onSubmit -- values:', values);
    if (voiceoverRef) {
      updateVoiceover(voiceoverRef, values, uid);
    } else {
      createVoiceover(wsId, uid, values);
    }
    onOpenChange(false);

    if (voiceoverRef === null) {
      router.push(ROUTES.VOICEOVERS.replace('[wsId]', wsId));
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{`${
            voiceoverRef ? 'Edit' : 'New'
          } voiceover`}</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <FormField
              control={form.control}
              name="title"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Title</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormDescription></FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="projects"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Projects</FormLabel>
                  <FormControl>
                    <Popover>
                      <PopoverTrigger asChild>
                        <div
                          className={
                            'w-full border px-3 py-1.5 rounded-md flex flex-wrap gap-1'
                          }
                        >
                          {field.value.length === 0 && (
                            <span className={'text-gray-500 text-sm'}>
                              Select projects
                            </span>
                          )}
                          {field.value.map((value, index) => {
                            const p = projectsData.find(
                              doc => doc.id === value
                            );
                            if (!p) return null;
                            const { name } = p;
                            return (
                              <Badge
                                key={index}
                                variant={'default'}
                                className={
                                  'rounded-full whitespace-nowrap pr-1.5'
                                }
                              >
                                {name}
                                <X
                                  className={'h-4 w-4 ml-0.5 cursor-pointer'}
                                  onClick={e => {
                                    e.stopPropagation();
                                    field.onChange(
                                      field.value.filter(
                                        (item: string) => item !== value
                                      )
                                    );
                                  }}
                                />
                              </Badge>
                            );
                          })}
                        </div>
                      </PopoverTrigger>
                      <PopoverContent className="p-0" align={'start'}>
                        <Command>
                          <CommandInput
                            placeholder="Search project..."
                            className="h-9"
                          />
                          <CommandList className="max-h-56 overflow-y-auto">
                            <CommandEmpty>No project found.</CommandEmpty>
                            <CommandGroup>
                              {projectsData?.map(p => {
                                const { id, name } = p;
                                return (
                                  <CommandItem
                                    key={id}
                                    value={name}
                                    onSelect={() => {
                                      field.onChange(
                                        field.value.includes(id)
                                          ? field.value.filter(
                                              (item: string) => item !== id
                                            )
                                          : [...field.value, id]
                                      );
                                    }}
                                  >
                                    {name}
                                    {field.value.includes(id) && (
                                      <CheckIcon
                                        className={cn('ml-auto h-4 w-4')}
                                      />
                                    )}
                                  </CommandItem>
                                );
                              })}
                            </CommandGroup>
                          </CommandList>
                        </Command>
                      </PopoverContent>
                    </Popover>
                  </FormControl>
                  <FormDescription></FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button type="submit" className={'float-end'}>
              {voiceoverRef ? 'Update' : 'Create voiceover'}
            </Button>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
export default VoiceoverFormView;
